<template>
  <v-container fluid>
    <v-row
      justify="end"
      no-gutters>
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="onBack()">
          <v-icon>
            mdi-chevron-left
          </v-icon>
          <span>
            ย้อนกลับ
          </span>
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="warning"
          depressed
          :ripple="false"
          style="width: fit-content;"
          @click="gotoEdit()">
          <v-icon>
            mdi-square-edit-outline
          </v-icon>
          <span>
            แก้ไขข้อมูล
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="form-container rounded-lg secondary--text">
      <v-col cols="12">
        <h3>
          ข้อมูลบทความ
        </h3>
        <v-divider class="mt-3" />
      </v-col>
      <v-col cols="12">
        <image-uploader
          v-model="formData.imageUrls"
          :max-file="1"
          square
          disabled />
      </v-col>
      <v-col
        cols="12"
        md="8">
        <p class="mb-1">
          ชื่อบทความ <span class="primary--text">(TH)</span>
        </p>
        <v-text-field
          v-model="formData.name"
          :rules="textBoxRules"
          color="primary"
          outlined
          dense
          hide-details
          required
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="8">
        <p class="mb-1">
          ชื่อบทความ <span class="primary--text">(EN)</span>
        </p>
        <v-text-field
          v-model="formData.translate.name"
          :rules="textBoxRules"
          color="primary"
          outlined
          dense
          hide-details
          required
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="8">
        <div class="mb-1">
          ประเภทบทความ
        </div>
        <v-select
          v-model="formData.type"
          :rules="textBoxRules"
          :items="types"
          item-text="text"
          item-value="value"
          color="primary"
          outlined
          dense
          hide-details
          required
          readonly />
      </v-col>
      <v-col
        cols="12"
        md="8">
        <p class="mb-1">
          เนื้อหา <span class="primary--text">(TH)</span>
        </p>
        <Editor
          v-model="formData.content"
          image
          disabled />
      </v-col>
      <v-col
        cols="12"
        md="8">
        <p class="mb-1">
          เนื้อหา <span class="primary--text">(EN)</span>
        </p>
        <Editor
          v-model="formData.translate.content"
          image
          disabled />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BlogsProvider from '@/resources/blogs.provider'
import ImageUploader from '@/components/ImageUploader.vue'
import Editor from '@/components/Editor.vue'

const BlogsService = new BlogsProvider()

export default {
  components: {
    ImageUploader,
    Editor
  },
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    formData: {
      name: '',
      imageUrls: [],
      type: 'news',
      tags: [],
      postDate: '',
      content: '',
      translate: {
        name: '',
        content: ''
      }
    },
    types: [
      {
        text: 'ข่าวสาร',
        value: 'news'
      },
      {
        text: 'กิจกรรม',
        value: 'event'
      }
    ]
  }),
  computed: {
    itemId () {
      return this.$route.params?.id || null
    }
  },
  mounted () {
    if (this.itemId) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'BlogList' })
    },
    gotoEdit () {
      this.$router.push({
        name: 'BlogEdit',
        params: {
          id: this.itemId
        }
      })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await BlogsService.getItemById(this.itemId)

        this.formData = {
          ...data,
          imageUrls: data.imageUrls.map((img) => ({
            file: null,
            preview: img,
            mediaUrl: img
          }))
        }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
